import { Box, Flex } from '@chakra-ui/react';
import { HeadMeta } from 'components/HeadMeta';
import { getSharedPageProps } from 'components/Layout';
import LoginForm from 'components/loginform/loginform';
import { colors } from 'constants/constants';
import { GetStaticProps } from 'next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import SessionContext from 'session/SessionContext';

export default function Login(): JSX.Element {
  const session = useContext(SessionContext);
  const router = useRouter();

  if (session.user) {
    router.replace('/mypages');
  }

  return (
    <Flex pos="relative" justify="center" align="center" h="calc(100vh - 60px)">
      <HeadMeta nodeName="Logga in" />
      <LoginForm />

      <Image src="/images/login.jpg" layout="fill" objectFit="cover" />

      <Box
        bg={colors.primary[900]}
        opacity={0.9}
        pos="absolute"
        top="0"
        left="0"
        w="100%"
        h="100%"
        zIndex="0"></Box>
    </Flex>
  );
}
export const getStaticProps: GetStaticProps = async () => {
  const sharedPageProps = await getSharedPageProps();

  return {
    props: { ...sharedPageProps },
  };
};
