import React, { useContext } from 'react';
import {
  Box,
  Button,
  FormControl,
  Input,
  Text,
  InputGroup,
  InputLeftElement,
  FormErrorMessage,
} from '@chakra-ui/react';
import { EmailIcon, LockIcon } from '@chakra-ui/icons';
import { colors, elevation } from 'constants/constants';
import Link from 'next/link';
import SessionContext, { ISessionContext } from 'session/SessionContext';
import { useRouter } from 'next/router';
import { Field, Form, Formik, FormikErrors, FormikHelpers } from 'formik';

import Logo from 'public/images/logo.svg';

interface FormValues {
  email: string;
  password: string;
}

export default function LoginForm(): JSX.Element {
  const session = useContext<ISessionContext>(SessionContext);
  const router = useRouter();

  async function handleSubmitasync(
    values: FormValues,
    { setSubmitting, setErrors }: FormikHelpers<FormValues>
  ) {
    const updatedPassword = await session.actions.signIn(values);
    setSubmitting(false);
    if (updatedPassword.error) {
      const err: FormikErrors<FormValues> = {
        email: null,
        password: null,
      };
      err[updatedPassword.error.field] = updatedPassword.error.message;
      setErrors(err);
    } else {
      router.push('/mypages');
    }
  }

  return (
    <Box
      zIndex="10"
      p="3em"
      textAlign="center"
      bg={colors.white}
      borderRadius="1em"
      boxShadow={elevation['+80']}
      w="100%"
      maxW="25em">
      <Logo
        fill={colors.primary[900]}
        width={150}
        style={{ margin: '0 auto' }}
      />

      {!session.user ? (
        <>
          <Text my="2em" color={colors.neutrals[300]}>
            MINA SIDOR
          </Text>
          <Formik
            initialValues={{ email: '', password: '' }}
            onSubmit={handleSubmitasync}>
            {(props) => {
              return (
                <Form>
                  <Field name="email">
                    {({ field, form }) => (
                      <FormControl
                        mb={3}
                        isInvalid={form.errors.email && form.touched.email}>
                        <InputGroup>
                          <InputLeftElement>
                            <EmailIcon color={colors.neutrals[300]} />
                          </InputLeftElement>
                          <Input
                            type="email"
                            isRequired={true}
                            {...field}
                            id="email"
                            placeholder="E-post"
                            bg="white"
                          />
                        </InputGroup>
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="password">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.password && form.touched.password
                        }>
                        <InputGroup>
                          <InputLeftElement>
                            <LockIcon color={colors.neutrals[300]} />
                          </InputLeftElement>
                          <Input
                            isRequired={true}
                            type="password"
                            {...field}
                            id="password"
                            placeholder="Lösenord"
                            bg="white"
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.password}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Button
                    mt={4}
                    w="100%"
                    variant="ml"
                    isLoading={props.isSubmitting}
                    type="submit">
                    Logga in
                  </Button>
                </Form>
              );
            }}
          </Formik>
          <Box mt="2" color={colors.neutrals[300]}>
            <Link href="/forgotpassword">
              <a>Glömt lösenordet?</a>
            </Link>
          </Box>
        </>
      ) : (
        'Du är inloggad som ' + session.user.email
      )}
    </Box>
  );
}
